import React from "react";
import tw from "twin.macro";
// import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
// import MainFeature from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FeatureWithSteps from "components/features/TwoColWithSteps.js";
// import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
// import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
// import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
// import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
// import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import GlobalStyles from 'styles/GlobalStyles';
// import styled from "styled-components";
import guy from "../pics/guy.png";



export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <><GlobalStyles />
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <Testimonial
          subheading={<Subheading>We are on a MISSION</Subheading>}
          heading={
            <>
              World Class Therapy <HighlightedText>For Everyone</HighlightedText>
            </>
          }
          description="We’re on a bold mission to make mental health support and personal growth accessible to all. Our AI is clinically trained on thousands of therapy sessions, research papers, and textbooks—giving you immediate, empathetic guidance anywhere in the world."
          description2="Whether you’re dealing with anxiety, relationship challenges, or simply want to understand yourself better, NewTherapy.ai is here to help."
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
              heading: "Mind-Blowing Insights",
              quote:
                "It’s like having a world-class therapist who’s always ready to listen—no scheduling, no judgment, and surprisingly human. It helped me clarify tough issues in record time.",
              customerName: "Beta User",
              customerTitle: "November 2024"
            },
            {
              stars: 5,
              profileImageSrc:
                guy,
              heading: "We're collecting more testimonials!",
              quote:
                "Email me at the bottom Contact Us link if you'd like to share your NewTherapy.ai story!",
              customerName: "Could be You",
              customerTitle: "Soon..."
            }
          ]}
        />
        <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              Why Choose Our <HighlightedText>AI Therapist?</HighlightedText>
            </>
          }
        />
        {/* <MainFeature2
          subheading={<Subheading>We are on a MISSION</Subheading>}
          heading={
            <>
              Join the AI Therapy <HighlightedText>Revolution</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          description="We are on an unstoppable mission to revolutionize mental health with the power of AI therapy. Our goal is bold but simple: deliver world-class therapy to everyone, everywhere for free."
          showDecoratorBlob={false}
          primaryButtonUrl="/chat"
          primaryButtonText="Chat Now"
          features={[
            {
              Icon: MoneyIcon,
              title: "Free",
              description: "World class therapy should be free for everyone always.",
              iconContainerCss: tw`bg-green-300 text-green-800`
            },
            {
              Icon: BriefcaseIcon,
              title: "Highest Standards",
              description: "Uncompromising standards, ensuring exceptional quality care for everyone, everywhere.",
              iconContainerCss: tw`bg-red-300 text-red-800`
            }
          ]}
        /> */}

        <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              Frequently Asked <HighlightedText>Questions</HighlightedText>
            </>
          }
          description="AI Therapy is a brand new industry with promise to provide free, accessible, and private mental health support to everyone."
          faqs={[
            {
              question: "What is an AI therapist, and how does it work?",
              answer:
                "An AI therapist uses advanced language models trained on thousands of therapy-related resources. It analyzes your text inputs to offer empathetic and relevant guidance, strategies, and encouragement—mimicking real therapy conversation patterns without replacing human professionals.",
            },
            {
              question: "Is NewTherapy.ai really free?",
              answer:
                "Absolutely. Our mission is to democratize mental health support. We offer a no-cost version to ensure everyone can access help whenever they need it, with optional premium features on the horizon to keep the service sustainable.",
            },
            {
              question: "How secure are my conversations?",
              answer:
                "Your privacy is top priority. You can choose “temporary mode” to keep sessions unrecorded or opt into saved sessions for continuity. All data is encrypted, and we never share private info with third parties. Check our Privacy Policy for details.",
            },
            {
              question: "Can it speak different languages?",
              answer:
                "Yes! Our AI therapist supports most major languages. Type in your preferred language, and the AI will adapt seamlessly.",
            },
            {
              question: "Is this a replacement for human therapy?",
              answer:
                "NewTherapy.ai is an incredible supplement but not a complete replacement for licensed mental health professionals. It can offer valuable perspective and coping strategies, but we encourage users with serious concerns to also consult qualified therapists.",
            },
          ]}
        />
        <GetStarted />
        <Footer />
      </AnimationRevealPage>
    </>
  );
}


{/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /> */}
{/* <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}

{/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}




// <Testimonial
//     subheading={<Subheading>Testimonials</Subheading>}
//     heading={
//       <>
//         Our Clients <HighlightedText>Love Us.</HighlightedText>
//       </>
//     }
//     testimonials={[
//       {
//         stars: 5,
//         profileImageSrc:
//           "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
//         heading: "Amazing User Experience",
//         quote:
//           "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
//         customerName: "Charlotte Hale",
//         customerTitle: "Director, Delos Inc."
//       },
//       {
//         stars: 5,
//         profileImageSrc:
//           "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
//         heading: "Love the Developer Experience and Design Principles !",
//         quote:
//           "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
//         customerName: "Adam Cuppy",
//         customerTitle: "Founder, EventsNYC"
//       }
//     ]}
//   />