import React, { useState } from "react";
import "./LandingPage.css";

function LandingPage() {
  // Example FAQ data
  const faqData = [
    {
      question: "What is an AI therapist, and how does it work?",
      answer:
        "An AI therapist uses advanced language models trained on thousands of therapy-related resources. It analyzes your text inputs to offer empathetic and relevant guidance, strategies, and encouragement—mimicking real therapy conversation patterns without replacing human professionals.",
    },
    {
      question: "Is NewTherapy.ai really free?",
      answer:
        "Absolutely. Our mission is to democratize mental health support. We offer a no-cost version to ensure everyone can access help whenever they need it, with optional premium features on the horizon to keep the service sustainable.",
    },
    {
      question: "How secure are my conversations?",
      answer:
        "Your privacy is top priority. You can choose “temporary mode” to keep sessions unrecorded or opt into saved sessions for continuity. All data is encrypted, and we never share private info with third parties. Check our Privacy Policy for details.",
    },
    {
      question: "Can it speak different languages?",
      answer:
        "Yes! Our AI therapist supports most major languages. Type in your preferred language, and the AI will adapt seamlessly.",
    },
    {
      question: "Is this a replacement for human therapy?",
      answer:
        "NewTherapy.ai is an incredible supplement but not a complete replacement for licensed mental health professionals. It can offer valuable perspective and coping strategies, but we encourage users with serious concerns to also consult qualified therapists.",
    },
  ];

  // Track which FAQ items are "open"
  const [openFaqIndices, setOpenFaqIndices] = useState([]);

  const handleToggleFAQ = (index) => {
    if (openFaqIndices.includes(index)) {
      // If it's already open, remove it
      setOpenFaqIndices(openFaqIndices.filter((i) => i !== index));
    } else {
      // Otherwise, add it
      setOpenFaqIndices([...openFaqIndices, index]);
    }
  };

  return (
    <div className="landing-container">
      {/* HEADER */}
      <header className="site-header">
        <nav className="main-nav">
          <a className="logo-link" href="/">
            {/* Replace with your actual logo */}
            <img
              src="YOUR_LOGO.svg"
              alt="NewTherapy.ai Logo"
              className="logo-img"
            />
            <h1 className="logo-text">
              NewTherapy.ai <sup>(Beta)</sup>
            </h1>
          </a>
          <ul className="nav-links">
            <li>
              <a href="#features">Features</a>
            </li>
            <li>
              <a href="#faq">FAQ</a>
            </li>
            <li>
              <a href="/chat" className="button primary-button">
                Chat Now
              </a>
            </li>
          </ul>
        </nav>
      </header>

      {/* HERO SECTION */}
      <section className="hero-section">
        <div className="hero-content">
          <h2 className="hero-title">Revolutionary AI Therapy</h2>
          <p className="hero-subtext">
            Discover the compassionate care, clinical insights, and life-changing
            advice offered by an advanced AI therapist—absolutely free.
          </p>
          <div className="hero-cta">
            <a href="/chat" className="button cta-button">
              Start Your Session
            </a>
          </div>
        </div>
        <div className="hero-illustration">
          {/* Replace with your illustration image */}
          <img src="hero_illustration.svg" alt="AI Therapist Illustration" />
        </div>
      </section>

      {/* MISSION / VALUE SECTION */}
      <section className="mission-section">
        <div className="mission-text">
          <h3>World-Class Therapy, For Everyone</h3>
          <p>
            We’re on a bold mission to make mental health support and personal
            growth accessible to all. Our AI is clinically trained on thousands
            of therapy sessions, research papers, and textbooks—giving you
            immediate, empathetic guidance anywhere in the world.
          </p>
          <p>
            Whether you’re dealing with anxiety, relationship challenges, or
            simply want to understand yourself better, NewTherapy.ai is here to
            help.
          </p>
        </div>
        <div className="testimonial">
          <h4>“Mind-Blowing Insights”</h4>
          <p className="testimonial-quote">
            “It’s like having a world-class therapist who’s always ready to
            listen—no scheduling, no judgment, and surprisingly human. It helped
            me clarify tough issues in record time.”
          </p>
          <p className="testimonial-user">
            — Beta User, <em>November 2024</em>
          </p>
        </div>
      </section>

      {/* FEATURES SECTION */}
      <section id="features" className="features-section">
        <h2>Why Choose Our AI Therapists?</h2>
        <p className="section-intro">
          Experience an evolving, evidence-based AI platform designed to be your
          mental health companion—24/7, anywhere, at no cost.
        </p>
        <div className="features-grid">
          {/* Example feature cards */}
          <div className="feature-card">
            <img src="icon_easy.svg" alt="Easy icon" />
            <h4>Easy & Judgment-Free</h4>
            <p>Conversations that feel safe, supportive, and free from stigma.</p>
          </div>
          <div className="feature-card">
            <img src="icon_24_7.svg" alt="24/7 icon" />
            <h4>24/7 Availability</h4>
            <p>Instant help, day or night—no appointments or waitlists.</p>
          </div>
          <div className="feature-card">
            <img src="icon_custom.svg" alt="Custom icon" />
            <h4>Customizable Sessions</h4>
            <p>Adapt the chat style and pace to match your unique needs.</p>
          </div>
          <div className="feature-card">
            <img src="icon_reliable.svg" alt="Reliable icon" />
            <h4>Reliable & Consistent</h4>
            <p>
              Clinically-informed AI that continually improves with feedback.
            </p>
          </div>
          <div className="feature-card">
            <img src="icon_fast.svg" alt="Fast icon" />
            <h4>Fast, On-Demand Help</h4>
            <p>Get immediate insights without scheduling or waiting in line.</p>
          </div>
          <div className="feature-card">
            <img src="icon_secure.svg" alt="Secure icon" />
            <h4>Private & Secure</h4>
            <p>
              Choose if chats are saved or kept temporary—your privacy, your
              choice.
            </p>
          </div>
        </div>
      </section>

      {/* FAQ SECTION */}
      <section id="faq" className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <p className="section-intro">
          Everything you need to know before starting your first session.
        </p>

        {faqData.map((faq, idx) => (
          <div key={idx} className="faq-item">
            <button
              className="faq-question"
              onClick={() => handleToggleFAQ(idx)}
            >
              {faq.question}
            </button>
            <div
              className={`faq-answer ${openFaqIndices.includes(idx) ? "open" : ""
                }`}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </section>

      {/* CTA SECTION */}
      <section className="cta-section">
        <div className="cta-text">
          <h3>Ready to Explore Your Mind?</h3>
          <p>
            Join thousands discovering insights, relief, and personal growth
            with our compassionate AI therapist.
          </p>
        </div>
        <div className="cta-buttons">
          <a href="/chat" className="button primary-button">
            Chat Now
          </a>
          <a href="mailto:support@newtherapy.ai" className="button secondary-button">
            Contact Us
          </a>
        </div>
      </section>

      {/* FOOTER */}
      <footer className="site-footer">
        <div className="footer-main">
          <div className="footer-col">
            <h5>Main</h5>
            <ul>
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms-of-service">Terms of Service</a>
              </li>
              <li>
                <a href="mailto:support@newtherapy.ai">Support</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h5>Product</h5>
            <ul>
              <li>
                <a href="/chat">Chat Now</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h5>Resources</h5>
            <ul>
              <li>
                <a href="https://988lifeline.org">Crisis Lifeline</a>
              </li>
              <li>
                <a href="https://thehotline.org/help">Domestic Violence Hotline</a>
              </li>
              <li>
                <a href="https://findahelpline.com/">Find a Helpline</a>
              </li>
            </ul>
          </div>
          <div className="footer-col brand-col">
            {/* Replace with your brand's logo */}
            <img
              src="YOUR_LOGO_LIGHT.svg"
              alt="Logo"
              className="footer-logo"
            />
            <p>© NewTherapy.ai All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;