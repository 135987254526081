export function getStarter(number = Math.floor(Math.random() * 11)) {
  const convoStarters = [
    "Hi! I'm X2, your highly qualified AI Therapist. What's been on your mind lately? I'm here to support you.",
    "Hello! I'm X2. As a well-trained AI Therapist, I'm here to help you navigate your thoughts and feelings. How can I assist you today?",
    "Hey! I'm X2, your AI Therapist. I've been designed to help you with care and expertise. What's something you'd like to talk about today?",
    "Hi there! I'm X2, a skilled AI Therapist here to provide a listening ear and thoughtful insights. What's on your mind today?",
    "Welcome! I'm X2, your AI Therapist. This is your safe space to share anything you'd like, and I'm here to help with empathy and understanding. What's been going on?",
    "Hello! I'm X2, a highly capable AI Therapist. My goal is to help you untangle any thoughts or feelings you'd like to explore. What's the first thing that comes to mind?",
    "Hey! I'm X2, your AI Therapist. Life can feel overwhelming sometimes, but I'm trained to help you sort through it. What’s something you'd like to focus on today?",
    "Hi! I'm X2, an experienced AI Therapist designed to listen without judgment and provide thoughtful support. How are you doing today?",
    "Hello! I'm X2, your AI Therapist. Let’s start wherever you feel comfortable. I've been developed to offer qualified assistance in understanding your thoughts and feelings. What's been on your heart or mind?",
    "Hey there! I'm X2, a knowledgeable AI Therapist here to chat and support you. Is there something you’ve been thinking about or feeling that you’d like to talk through?",
    "Hi! I'm X2, your AI Therapist. Sharing even small thoughts can sometimes make a big difference. I'm here to listen with expertise and care. What's the first thing you'd like to share?"
  ];

  return convoStarters[number];
};