import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { FaMicrophone } from "react-icons/fa";
import {
  ArrowIcon,
  DownloadIcon,
  DownArrowIcon,
  isTokenExpired,
  handleDownloadPDF,
  startRecording,
  stopRecording,
  handleRecordingStop,
  handleMicClick,
} from "./HelperFuncs";
import gearIcon from "./gear.svg";
import "./App.css";

// Sample conversation starter
const { getStarter } = require("./convoStarters");
// For decoding JWT
const { jwtDecode } = require("jwt-decode");

function ChatPage() {
  // --------------------- STATE ---------------------
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isLoadingResp, setIsLoadingResp] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [theme, setTheme] = useState("light");
  const [recording, setRecording] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isTemporaryChat, setIsTemporaryChat] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);

  // Auth
  const [jwtToken, setJwtToken] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [authError, setAuthError] = useState("");
  const [authLoading, setAuthLoading] = useState(false);

  // For "delete data" success text
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  // Store user email from token for display
  const [decodedEmail, setDecodedEmail] = useState("");

  // Show/hide scroll-down button
  const [showScrollDown, setShowScrollDown] = useState(false);

  // Onboarding modal
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  // Insights modal
  const [showInsightsModal, setShowInsightsModal] = useState(false);

  // FEEDBACK STATES (Only for logged-in users)
  const [userMsgCount, setUserMsgCount] = useState(0);

  const [shouldAsk5Feedback, setShouldAsk5Feedback] = useState(
    localStorage.getItem("shouldAsk5Feedback") === "false" ? false : true
  );
  const [shouldAsk10Feedback, setShouldAsk10Feedback] = useState(
    localStorage.getItem("shouldAsk10Feedback") === "false" ? false : true
  );

  const [showFiveFeedbackModal, setShowFiveFeedbackModal] = useState(false);
  const [fiveFeedbackText, setFiveFeedbackText] = useState("");
  const [showFiveThankYou, setShowFiveThankYou] = useState(false);

  const [showTenFeedbackModal, setShowTenFeedbackModal] = useState(false);
  const [tenFeedbackRating, setTenFeedbackRating] = useState(7);
  const [tenFeedbackText, setTenFeedbackText] = useState("");
  const [showTenThankYou, setShowTenThankYou] = useState(false);

  // GUEST MODE
  const [guestId, setGuestId] = useState(null);
  const [guestAgreedToTos, setGuestAgreedToTos] = useState(false);
  const [guestMessagesUsed, setGuestMessagesUsed] = useState(0);
  const GUEST_LIMIT = 5;
  const [showGuestTosModal, setShowGuestTosModal] = useState(false);
  const [pendingGuestMessage, setPendingGuestMessage] = useState(null);

  // Refs
  const chatWindowRef = useRef(null);
  const inputRef = useRef(null);

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  // --------------------- EFFECTS --------------------
  useEffect(() => {
    const storedToken = localStorage.getItem("app_jwt");
    if (storedToken) {
      if (isTokenExpired(storedToken)) {
        localStorage.removeItem("app_jwt");
        setJwtToken(null);
        setShowAuthModal(true);
      } else {
        setJwtToken(storedToken);
        try {
          const decoded = jwtDecode(storedToken);
          if (decoded.email) {
            setDecodedEmail(decoded.email);
          }
        } catch (err) {
          console.error("Decoding error:", err);
        }
        const hasSeenOnboarding = localStorage.getItem("hasSeenOnboarding");
        if (!hasSeenOnboarding) {
          setShowOnboardingModal(true);
        }
      }
    } else {
      // If no JWT, set up guest mode
      initializeGuestMode();
    }
  }, []);

  useEffect(() => {
    if (jwtToken) {
      localStorage.setItem("app_jwt", jwtToken);
      setShowAuthModal(false);
      try {
        const decoded = jwtDecode(jwtToken);
        if (decoded.email) {
          setDecodedEmail(decoded.email);
        }
      } catch (e) {
        console.log("Error decoding token:", e);
      }
      const hasSeenOnboarding = localStorage.getItem("hasSeenOnboarding");
      if (!hasSeenOnboarding) {
        setShowOnboardingModal(true);
      }
    } else {
      localStorage.removeItem("app_jwt");
      setDecodedEmail("");
    }
  }, [jwtToken]);

  // Fix mobile 100vh
  useEffect(() => {
    const updateVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    updateVH();
    window.addEventListener("resize", updateVH);
    window.addEventListener("orientationchange", () => {
      setTimeout(updateVH, 100);
    });
    return () => {
      window.removeEventListener("resize", updateVH);
      window.removeEventListener("orientationchange", updateVH);
    };
  }, []);

  // Initial AI message
  useEffect(() => {
    const initialAIMessage = {
      sender: "ai",
      text: getStarter(),
    };
    setMessages([initialAIMessage]);
  }, []);

  // Scroll on new messages
  useEffect(() => {
    if (!chatWindowRef.current) return;
    const chatEl = chatWindowRef.current;
    const distanceFromBottom =
      chatEl.scrollHeight - (chatEl.scrollTop + chatEl.clientHeight);

    if (!isResponding && distanceFromBottom < 80) {
      chatEl.scrollTo({ top: chatEl.scrollHeight, behavior: "smooth" });
    }
  }, [messages, isResponding]);

  // Show/hide scroll-down button
  useEffect(() => {
    const handleScroll = () => {
      if (!chatWindowRef.current) return;
      const chatEl = chatWindowRef.current;
      const distanceFromBottom =
        chatEl.scrollHeight - (chatEl.scrollTop + chatEl.clientHeight);
      setShowScrollDown(distanceFromBottom > 100);
    };

    if (chatWindowRef.current) {
      chatWindowRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatWindowRef.current) {
        chatWindowRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  // --------------------- GUEST MODE ---------------------
  function initializeGuestMode() {
    let storedGuestId = localStorage.getItem("guestId");
    let storedAgreed = localStorage.getItem("guestAgreedToTos") === "true";
    let storedUsed = Number(localStorage.getItem("guestMessagesUsed") || 0);

    if (!storedGuestId) {
      storedGuestId = generateGuestId();
      localStorage.setItem("guestId", storedGuestId);
    }
    setGuestId(storedGuestId);
    setGuestAgreedToTos(storedAgreed);
    setGuestMessagesUsed(storedUsed);
  }

  function generateGuestId() {
    return (
      "guest_" +
      Math.random().toString(36).substring(2, 10) +
      Date.now().toString().slice(-4)
    );
  }

  // --------------------- FEEDBACK HANDLERS ---------------------
  // Only for logged in users
  const postFeedback = (payload) => {
    if (!jwtToken) return;
    axios
      .post("/api/ntfeedback", payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      })
      .catch((err) => {
        console.error("Feedback submission failed:", err);
      });
  };

  const handleSubmitFiveFeedback = () => {
    if (fiveFeedbackText.trim() !== "") {
      postFeedback({
        feedbackType: "5-message",
        text: fiveFeedbackText.trim(),
      });
    }
    setShowFiveThankYou(true);
    setTimeout(() => {
      setShowFiveFeedbackModal(false);
      setShowFiveThankYou(false);
      setFiveFeedbackText("");
    }, 1000);
  };

  const handleSubmitTenFeedback = () => {
    postFeedback({
      feedbackType: "10-message",
      rating: tenFeedbackRating,
      text: tenFeedbackText.trim(),
    });
    setShowTenThankYou(true);
    setTimeout(() => {
      setShowTenFeedbackModal(false);
      setShowTenThankYou(false);
      setTenFeedbackRating(7);
      setTenFeedbackText("");
    }, 1000);
  };

  // --------------------- MAIN FUNCTIONS ------------------------
  const handleAuthSubmit = async (e) => {
    e.preventDefault();
    setAuthError("");
    if (!email || !password) {
      setAuthError("Email and password are required.");
      return;
    }
    setAuthLoading(true);
    try {
      const resp = await axios.post("/api/web/auth", {
        email,
        password,
        agreedToTerms,
      });
      if (resp.data && resp.data.token) {
        setJwtToken(resp.data.token);
      }
      setEmail("");
      setPassword("");
      setAgreedToTerms(false);
    } catch (err) {
      console.error("Auth error:", err?.response?.data || err);
      const message =
        err?.response?.data?.error || "Authentication failed, please try again.";
      setAuthError(message);
    } finally {
      setAuthLoading(false);
    }
  };

  // Toggle theme
  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  // Download transcript
  const onDownloadPDF = () => {
    handleDownloadPDF(messages);
  };

  const handleSend = async (message = inputText) => {
    if (!message.trim()) return;

    // Auto-refocus text area
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.focus();
    }

    // If the user is logged in, track & possibly trigger feedback modals
    if (jwtToken) {
      const newUserMsgCount = userMsgCount + 1;
      setUserMsgCount(newUserMsgCount);

      // Trigger feedback at 5/10 only for logged-in users
      if (newUserMsgCount === 5) {
        if (shouldAsk5Feedback) {
          setShowFiveFeedbackModal(true);
          localStorage.setItem("shouldAsk5Feedback", "false");
          setShouldAsk5Feedback(false);
        } else {
          localStorage.setItem("shouldAsk5Feedback", "true");
          setShouldAsk5Feedback(true);
        }
      }
      if (newUserMsgCount === 10) {
        if (shouldAsk10Feedback) {
          setShowTenFeedbackModal(true);
          localStorage.setItem("shouldAsk10Feedback", "false");
          setShouldAsk10Feedback(false);
        } else {
          localStorage.setItem("shouldAsk10Feedback", "true");
          setShouldAsk10Feedback(true);
        }
      }
    }

    // If guest user hasn't accepted TOS => show TOS modal
    if (!jwtToken && !guestAgreedToTos) {
      setPendingGuestMessage(message);
      setShowGuestTosModal(true);
      return;
    }

    // If guest used up 5 messages => show signup/login modal
    if (!jwtToken && guestMessagesUsed >= GUEST_LIMIT) {
      setShowAuthModal(true);
      return;
    }

    // Add the user's message to state
    const userMessage = { sender: "user", text: message };
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInputText("");

    setIsLoadingResp(true);
    setIsResponding(true);

    // Decide if logged in or guest
    if (jwtToken) {
      await sendLoggedInMessage(updatedMessages, message);
    } else {
      await sendGuestMessage(updatedMessages, message);
    }
  };

  const sendLoggedInMessage = async (updatedMessages, message) => {
    try {
      const isNewSession =
        messages.length === 1 && messages[0].sender === "ai";

      const conversation_history = updatedMessages.map((msg) => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.text,
      }));

      const requestBody = {
        message,
        conversation_history,
        isTemporaryChat,
        premium: true,
        isNewSession,
      };

      const response = await axios.post("/api/web/chat", requestBody, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      const aiText = response.data.aiText;
      displayAIResponse(aiText);
    } catch (err) {
      console.error(err);
      alert("An error occurred while sending your message.");
      setIsResponding(false);
    } finally {
      setIsLoadingResp(false);
    }
  };

  const sendGuestMessage = async (updatedMessages, message) => {
    try {
      // local usage count
      const conversation_history = updatedMessages.map((msg) => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.text,
      }));

      const reqBody = {
        guestId,
        agreedToTos: guestAgreedToTos,
        message,
        conversation_history,
        isTemporaryChat,
      };

      const response = await axios.post("/api/web/chat-guest", reqBody);
      const aiText = response.data.aiText;
      displayAIResponse(aiText);

      // increment local usage
      const newUsage = guestMessagesUsed + 1;
      setGuestMessagesUsed(newUsage);
      localStorage.setItem("guestMessagesUsed", String(newUsage));
    } catch (err) {
      console.error(err);
      if (err.response) {
        alert(
          `Error ${err.response.status}: ${err.response.data.error || "Guest chat error."
          }`
        );
      } else {
        alert("An unexpected error occurred (guest mode).");
      }
      setIsResponding(false);
    } finally {
      setIsLoadingResp(false);
    }
  };

  // Animate AI "typing"
  const displayAIResponse = (text) => {
    let index = 0;
    const words = text.split(" ");
    const aiMessage = { sender: "ai", text: "" };
    setMessages((prev) => [...prev, aiMessage]);

    const interval = setInterval(() => {
      if (index < words.length) {
        aiMessage.text += (index > 0 ? " " : "") + words[index];
        setMessages((prev) => {
          const copy = [...prev];
          copy[copy.length - 1] = { ...aiMessage };
          return copy;
        });
        index++;
      } else {
        clearInterval(interval);
        setIsResponding(false);
        // re-focus the textarea
        if (inputRef.current) {
          inputRef.current.focus();
        }

        if (chatWindowRef.current) {
          chatWindowRef.current.scrollTo({
            top: chatWindowRef.current.scrollHeight,
            behavior: "smooth",
          });
        }
      }
    }, 40);
  };

  // Mic
  const onMicClick = async () => {
    await handleMicClick({
      recording,
      startRecordingFn: async () =>
        startRecording({
          audioChunksRef,
          streamRef,
          mediaRecorderRef,
          setRecording,
          recordingTimeoutRef,
          MAX_RECORDING_TIME: 60,
          handleRecordingStop: (args) =>
            handleRecordingStop({
              ...args,
              setIsTranscribing,
              setInputText,
            }),
        }),
      stopRecordingFn: () =>
        stopRecording({
          mediaRecorderRef,
          streamRef,
          setRecording,
          recordingTimeoutRef,
        }),
    });
  };

  // Logout
  const handleLogout = () => {
    setJwtToken(null);
    setGuestId(null);
    setGuestAgreedToTos(false);
    setGuestMessagesUsed(0);
    localStorage.removeItem("guestId");
    localStorage.removeItem("guestAgreedToTos");
    localStorage.removeItem("guestMessagesUsed");
    setShowAuthModal(true);
    initializeGuestMode();
  };

  // Delete user data
  const handleDeleteData = async () => {
    if (!jwtToken) {
      alert("You must be logged in to delete data.");
      return;
    }
    try {
      const resp = await axios.delete("/api/web/data", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      console.log(resp.data);
      setDeleteSuccess(true);
    } catch (err) {
      console.error("Error deleting data:", err);
      alert("Failed to delete conversation data. Please try again.");
    }
  };

  // Auto-resize
  const handleInputChange = (e) => {
    setInputText(e.target.value);
    e.target.style.height = "auto";
    const maxLines = 5;
    const lineHeight = 20;
    const maxHeight = maxLines * lineHeight;
    const newHeight = Math.min(e.target.scrollHeight, maxHeight);
    e.target.style.height = newHeight + "px";
  };

  // Scroll to bottom
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTo({
        top: chatWindowRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  // Onboarding
  const handleCloseOnboarding = () => {
    localStorage.setItem("hasSeenOnboarding", "true");
    setShowOnboardingModal(false);
  };

  // Temporary Chat
  const handleTempChatToggle = () => {
    const conversationStarted = messages.length > 1;
    const nextVal = !isTemporaryChat;
    if (nextVal && conversationStarted) {
      const confirmSwitch = window.confirm(
        "Switching to a temporary chat will clear your current conversation. Continue?"
      );
      if (confirmSwitch) {
        setMessages([]);
        setIsTemporaryChat(true);
      }
    } else {
      setIsTemporaryChat(nextVal);
    }
  };

  // Guest TOS acceptance
  const handleGuestTosAccept = () => {
    setGuestAgreedToTos(true);
    localStorage.setItem("guestAgreedToTos", "true");
    setShowGuestTosModal(false);

    // If we had a pending message, send it now
    if (pendingGuestMessage) {
      const msgToSend = pendingGuestMessage;
      setPendingGuestMessage(null);
      // Slight delay to ensure modal closes
      setTimeout(() => {
        handleSend(msgToSend);
      }, 150);
    }
  };

  return (
    <div className={`yes-therapy-container ${theme}`}>
      {/* Header */}
      <header className="main-header">
        <h1 className="title">NewTherapy.ai</h1>

        {/* INSIGHTS BUTTON */}
        <button
          className="insights-button"
          onClick={() => setShowInsightsModal(true)}
          aria-label="Show Insights Modal"
        >
          Insights
        </button>

        {/* Settings (gear) button */}
        <button
          className="settings-button"
          onClick={() => setIsSettingsOpen(true)}
          aria-label="Open Settings"
        >
          <img src={gearIcon} alt="Settings" />
        </button>
      </header>

      {/* Insights Modal */}
      {showInsightsModal && (
        <div
          className="insights-modal-overlay"
          onClick={() => setShowInsightsModal(false)}
        >
          <div
            className="insights-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="insights-header">
              <h2>Insights</h2>
              <button
                className="close-insights"
                onClick={() => setShowInsightsModal(false)}
              >
                &times;
              </button>
            </div>
            <p>
              <strong>Coming Soon!</strong> We’ll provide deeper AI-powered
              insights, including a personalized summary to help you discover
              strengths and areas for growth. Stay tuned!
            </p>
          </div>
        </div>
      )}

      {/* Settings Modal */}
      {isSettingsOpen && (
        <div
          className="settings-modal-overlay"
          onClick={() => setIsSettingsOpen(false)}
        >
          <div
            className="settings-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="settings-header">
              <h2>Settings</h2>
              <button
                className="close-settings"
                onClick={() => setIsSettingsOpen(false)}
              >
                &times;
              </button>
            </div>

            <div className="settings-option">
              <label htmlFor="themeToggle">Dark Mode</label>
              <label className="switch">
                <input
                  id="themeToggle"
                  type="checkbox"
                  onChange={toggleTheme}
                  checked={theme === "dark"}
                />
                <span className="slider" />
              </label>
            </div>

            <div className="settings-option">
              <label htmlFor="tempChatToggle">
                Temporary Chat (No stored history)
              </label>
              <label className="switch">
                <input
                  id="tempChatToggle"
                  type="checkbox"
                  onChange={handleTempChatToggle}
                  checked={isTemporaryChat}
                />
                <span className="slider" />
              </label>
            </div>
            <p className="settings-note">
              <em>
                If enabled, we won't save past messages. The AI won't recall
                context once you exit. Switching it on mid-conversation clears
                your chat.
              </em>
            </p>

            <div style={{ marginTop: "1rem" }}>
              <p className="delete-data" onClick={handleDeleteData}>
                Permanently Clear AI Memory
              </p>
              {deleteSuccess && (
                <p className="delete-success">
                  All conversation data has been deleted from our servers.
                </p>
              )}
              <p className="settings-note">
                <em>
                  This erases all your saved sessions on our end. If you'd like
                  a fresh start, just tap here!
                </em>
              </p>
            </div>

            {/* Download PDF as label + icon to match toggle style */}
            <div className="settings-option">
              <label>Download PDF Transcript</label>
              <button
                className="icon-download-button"
                onClick={onDownloadPDF}
                aria-label="Download Transcript"
              >
                <DownloadIcon />
              </button>
            </div>

            <div style={{ marginTop: "2rem", textAlign: "center" }}>
              {jwtToken ? (
                <button onClick={handleLogout}>Logout</button>
              ) : (
                // FIX: Close Settings before opening the Login modal
                <button
                  onClick={() => {
                    setIsSettingsOpen(false);
                    setShowAuthModal(true);
                  }}
                >
                  Login
                </button>
              )}
            </div>

            {jwtToken && decodedEmail && (
              <div style={{ marginTop: "1rem", textAlign: "center" }}>
                <p>Logged in as {decodedEmail}</p>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Onboarding Modal */}
      {showOnboardingModal && (
        <div className="onboarding-overlay" onClick={handleCloseOnboarding}>
          <div
            className="onboarding-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>Welcome to NewTherapy.ai</h2>
            <p>
              I’m your specialized AI therapist, trained with clinically backed
              psychological methods and knowledge. My expertise comes from
              extensive study of modern psychology textbooks, peer-reviewed
              research, and publicly available therapy transcripts.
              <br />
              <br />
              My goal is to provide empathetic, insightful responses that guide
              you toward personal growth and well-being. I invite you to explore
              your thoughts and feelings openly in this safe, confidential
              space—your privacy and comfort are my top priorities.
            </p>
            <button
              className="close-onboarding-button"
              onClick={handleCloseOnboarding}
            >
              Begin
            </button>
          </div>
        </div>
      )}

      {/* Guest TOS Modal */}
      {showGuestTosModal && !jwtToken && (
        <div className="auth-overlay">
          <div className="auth-container">
            <h2>Proceed as Guest</h2>
            <p style={{ marginBottom: "1.2rem" }}>
              You can continue as a guest if you agree to our{" "}
              <a
                href="/terms-of-service"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "underline" }}
              >
                Privacy Policy
              </a>
              .
            </p>
            <div style={{ margin: "1rem 0" }}>
              <label>
                <input
                  type="checkbox"
                  checked={guestAgreedToTos}
                  onChange={() => setGuestAgreedToTos(!guestAgreedToTos)}
                />{" "}
                I agree to the Terms and Privacy.
              </label>
            </div>

            <button
              className={`auth-button ${!guestAgreedToTos ? "disabled" : ""
                }`}
              disabled={!guestAgreedToTos}
              onClick={handleGuestTosAccept}
              style={{ width: "100%", marginTop: "1rem" }}
            >
              Accept & Continue as Guest
            </button>

            <div
              style={{
                marginTop: "1.2rem",
                fontSize: "0.9rem",
                opacity: 0.9,
                textAlign: "center",
              }}
            >
              <p style={{ margin: 0 }}>
                <em>Or </em>
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowGuestTosModal(false);
                    setPendingGuestMessage(null);
                    setShowAuthModal(true);
                  }}
                >
                  Sign Up / Login
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {/* Chat Window */}
      <div className="chat-window" ref={chatWindowRef}>
        <div className="temp-chat-info">
          {isTemporaryChat ? (
            <p>
              <strong>Temporary Chat Enabled.</strong> (No memory saved)
            </p>
          ) : (
            <p>Therapist memory is enabled. (Session is saved)</p>
          )}
        </div>

        {messages.map((msg, idx) => (
          <div
            key={idx}
            className={`message-bubble fade-in-message ${msg.sender === "user" ? "user" : "ai"
              }`}
          >
            <div className="bubble-label">
              {msg.sender === "user" ? "You" : "Therapist"}
            </div>
            <div className="bubble-text">
              {msg.sender === "ai" ? (
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                  {msg.text}
                </ReactMarkdown>
              ) : (
                msg.text
              )}
            </div>
          </div>
        ))}

        {isLoadingResp && (
          <div className="typing-indicator">
            Your AI therapist is thinking carefully
            <span className="dot dot1">.</span>
            <span className="dot dot2">.</span>
            <span className="dot dot3">.</span>
          </div>
        )}

        {showScrollDown && (
          <button className="scroll-down-button" onClick={scrollToBottom}>
            <DownArrowIcon />
          </button>
        )}
      </div>

      {/* Input Bar */}
      <div className="input-bar-container">
        <div className="input-bar">
          <textarea
            ref={inputRef}
            className="chat-input"
            value={inputText}
            placeholder="What’s on your mind?"
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (!isResponding) {
                  handleSend();
                }
              }
            }}
            rows="1"
          />

          {isTranscribing ? (
            <div className="spinner" aria-label="Transcribing..."></div>
          ) : (
            <button
              className={`mic-button ${recording ? "active" : ""}`}
              onClick={onMicClick}
              disabled={isResponding}
              aria-label="Toggle Microphone"
            >
              <FaMicrophone />
            </button>
          )}

          <button
            className="send-button"
            onClick={() => handleSend()}
            disabled={isResponding}
            aria-label="Send Message"
          >
            <ArrowIcon />
          </button>
        </div>
      </div>

      {/* Auth Modal */}
      {showAuthModal && (
        <div className="auth-overlay">
          <div className="auth-container">
            <h2>Welcome to NewTherapy.ai</h2>
            <form onSubmit={handleAuthSubmit} className="auth-form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={authLoading}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={authLoading}
                  required
                />
              </div>
              <div className="form-group checkbox-container">
                <label htmlFor="terms" className="tos-text">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                    disabled={authLoading}
                  />
                  I agree to the{" "}
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </label>
              </div>

              {authError && <p className="auth-error">{authError}</p>}

              <div className="button-container">
                <button
                  type="submit"
                  className={`auth-button ${!agreedToTerms ? "disabled" : ""
                    }`}
                  disabled={!agreedToTerms || authLoading}
                >
                  {authLoading ? "Signing Up..." : "Sign Up"}
                </button>
                <button
                  type="submit"
                  className={`auth-button ${!agreedToTerms ? "disabled" : ""
                    }`}
                  disabled={!agreedToTerms || authLoading}
                >
                  {authLoading ? "Logging In..." : "Login"}
                </button>
              </div>

              {authLoading && (
                <div style={{ marginTop: "1rem", textAlign: "center" }}>
                  <div className="spinner" aria-label="Authenticating..."></div>
                </div>
              )}
            </form>
          </div>
        </div>
      )}

      {/* ================= FEEDBACK MODALS ================= */}
      {showFiveFeedbackModal && jwtToken && (
        <div
          className="feedback-overlay"
          onClick={() => setShowFiveFeedbackModal(false)}
        >
          <div
            className="feedback-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {showFiveThankYou ? (
              <div style={{ textAlign: "center", padding: "1rem" }}>
                <h3>Thank You!</h3>
                <p>Your feedback is appreciated.</p>
              </div>
            ) : (
              <>
                <h3>We Value Your Thoughts</h3>
                <p>
                  You’ve shared a few messages so far. Do you have any quick
                  feedback or suggestions on how we could improve NewTherapy.ai?
                </p>
                <textarea
                  placeholder="Optional: Share your thoughts…"
                  value={fiveFeedbackText}
                  onChange={(e) => setFiveFeedbackText(e.target.value)}
                  rows={3}
                />
                <div className="feedback-button-row">
                  <button onClick={() => setShowFiveFeedbackModal(false)}>
                    No Thanks
                  </button>
                  <button onClick={handleSubmitFiveFeedback}>Submit</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {showTenFeedbackModal && jwtToken && (
        <div
          className="feedback-overlay"
          onClick={() => setShowTenFeedbackModal(false)}
        >
          <div
            className="feedback-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            {showTenThankYou ? (
              <div style={{ textAlign: "center", padding: "1rem" }}>
                <h3>Thank You!</h3>
                <p>Your rating was received.</p>
              </div>
            ) : (
              <>
                <h3>Quick Check-In</h3>
                <p>
                  On a scale of 0–10, how helpful has this session felt so far?
                </p>
                <input
                  type="range"
                  min="0"
                  max="10"
                  value={tenFeedbackRating}
                  onChange={(e) => setTenFeedbackRating(e.target.value)}
                  className="feedback-slider"
                />
                <div className="feedback-slider-labels">
                  <span>0</span>
                  <span>10</span>
                </div>
                <p>
                  {tenFeedbackRating}/10 –{" "}
                  {tenFeedbackRating <= 3
                    ? "I'm sorry it's not been very helpful."
                    : tenFeedbackRating <= 7
                      ? "Thanks for letting us know!"
                      : "Great, we're glad it's helping!"}
                </p>
                <textarea
                  placeholder="Optional: Any suggestions or feedback?"
                  value={tenFeedbackText}
                  onChange={(e) => setTenFeedbackText(e.target.value)}
                  rows={2}
                />
                <div className="feedback-button-row">
                  <button onClick={() => setShowTenFeedbackModal(false)}>
                    Cancel
                  </button>
                  <button onClick={handleSubmitTenFeedback}>Submit</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatPage;